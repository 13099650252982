export var getTime = (times) => {
    var date = new Date(times)
    var Year = date.getFullYear()
    var Month = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1)
    var Day = date.getDate();
    Day = Day < 10 ? 0 + Day : Day
    var Hours = date.getHours();
    Hours = Hours < 10 ? "0" + Hours : Hours
    var Minutes = date.getMinutes();
    Minutes = Minutes < 10 ? "0" + Minutes : Minutes
    var Seconds = date.getSeconds();
    Seconds = Seconds < 10 ? "0" + Seconds : Seconds
    let time = `${Year}-${Month}-${Day} ${Hours}:${Minutes}:${Seconds}`
		let setwa =Year+''+Month+''+Day+''+Hours+''+Minutes+''+Seconds
    return { Year, Month, Day, Hours, Minutes, Seconds, time,setwa };
}
// 手机号
export const isMobile = (phone) => {
  const phones = /^0?(13[0-9]|14[0-9]|15[0-9]|16[0-9]|17[0-9]|18[0-9]|19[0-9])[0-9]{8}$/
  return phones.test(phone)
}
// 邮箱
export const isemail = (phone) => {
  const phones = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/
  return phones.test(phone)
}
//正整数不包含0
export const integersa = (trger) => {
  const trgse = /^([1-9]\d*|[-1]{1,1})$/
  return trgse.test(trger)
}
export const inttext = (trger) => {
  const trgse = /^[A-Za-z0-9-_]+$/
  return trgse.test(trger)
}