import request from '../utils/request.js'

//获取工单列表
export function workList(params) {
    return request({
        url: `/workOrder/list`,
        method: 'get',
        params,
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
//关闭工单列表
export function closeWork(params) {
    return request({
        url: `/workOrder/close`,
        method: 'PUT',
        params,
    })
}
//添加管理员
export function addManage(params) {
    return request({
        url: `/admin/add`,
        method: 'post',
        data: params,
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
//编辑管理员
export function editAolse(params) {
    return request({
        url: `/admin/edit_admin_roles`,
        method: 'get',
        data: params,
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
//获取工单数据
	export function Workdata(data) {
		return request({
				url: `/workOrder/byWorkOrder`,
				method: 'get',
				params:data,
		})
	}
//用户提交
export function workUpload(data) {
		return request({
				url: `/workOrder/upload`,
				method: 'post',
				data,
				headers: {
						'Content-Type': 'application/json'
				}
		})
}

//获取订单列表
export function OderList(params) {
    return request({
        url: `/order/list`,
        method: 'get',
        params,
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//获取流量账单列表
export function billListApi(params) {
    return request({
        url: `/bill/detail`,
        method: 'get',
        params,
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//获取流量账单列表
export function billListDetailApi(params) {
    return request({
        url: `/bill/itemdetail`,
        method: 'get',
        params,
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
//获取流量总量
export function billzonl(params) {
    return request({
        url: `bill/getCount`,
        method: 'get',
        params,
        headers: {
            'Content-Type': 'application/json'
        }
    })
}