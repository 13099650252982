<template>
  <div class="bgcard">
    <div class="title">
      <div class="line"></div>
      <p>{{ $t("lang.writeWorkOrder") }}</p>
    </div>
		<div class="questionlist">
			<div class="questionone">
				<p>* {{$t("lang.Problemdescription")}}：</p>
				<textarea v-model="userdiser.problemtext" maxlength="256" :placeholder="$t('lang.Pleaseinputthecontent')+'（'+$t('lang.Upcharacters')+'）'"></textarea>
			</div>
			<div class="questiontwo">
				<p>{{$t("lang.contactnumbers")}}：</p>
				<input v-model="userdiser.phone" maxlength="11"/>
				<span>{{$t("lang.Defaultregisteredmobilephone")}}</span>
			</div>
			<div class="questionthree">
				<p>{{$t("lang.Contactemail")}}：</p>
				<input v-model="userdiser.email" />
				<span>{{$t("lang.Defaultregisteredmobileemail")}}</span>
			</div>
			<div class="questionfour">
				<p>{{$t("lang.enclosure")}}：</p>
				<el-upload
					class="upload-demo"
					:action="url"
					:before-remove="beforeRemove"
					:limit="3"
					:headers="headers"
					list-type="picture"
					:on-exceed=" () => { $message.error($t('lang.Themaximumnumber'));}"
					:file-list="fileList"
					accept=".jpg,.jpeg,.png"
					:on-success="success"
					:before-upload="beforeUpload"
					 :on-remove='imgremove'
				>
					<template #trigger>
						<el-button size="small" type="primary">+ {{$t("lang.Addfile")}}</el-button>
					</template>
				</el-upload>
			</div>
		</div>
		<div class="btnstyle">
			<button @click="subWorkMsg">{{$t("lang.submit")}}</button>
			<button @click="retuen">{{$t("lang.return")}}</button>
		</div>
  </div>
</template>

<script>
import * as work from "@/api/manage.js";
import * as baseURL from "@/utils/request.js";
import * as RegExp from '@/ui-utils/RegExp.js'
import {isemail } from '@/utils/time'
export default {
  data() {
    return {
      fileList: [],
      userdiser:{
				problemtext: "",
				phone: "",
				email: "",
				file:[],
			},
			url:'',
			tokens:'',
			filessa:true
    };
  },
	mounted() {
		this.url = baseURL.upload;
		let accessToken = sessionStorage.getItem('token')
		this.tokens=JSON.parse(accessToken)
		this.userdiser.phone = JSON.parse(sessionStorage.getItem("user")).mobile;
		let email = JSON.parse(sessionStorage.getItem("user")).email;
		if(isemail(email)){
			this.userdiser.email=email
		}
	},
  methods: {
		//上传成功之后
		success(res) {
			if (res) {
				this.$message.success(this.$t("lang.Uploadsucceeded"));
				this.userdiser.file.push(res.data.path)
			}
		},
		//删除文件之前
		beforeRemove(file) {
			if(this.filessa){
				return this.$confirm(this.$t("lang.Areyousureyouwanttoremove")+`${file.name}？`);
			}
		},
		//删除文件
		imgremove(file){
			if(this.filessa){
				let sk=this.userdiser.file.indexOf(file.response.data.path)
				this.userdiser.file.splice(sk,1)
			}
		},
		//上传文件之前
		beforeUpload(file) {
			this.filessa=file
			let isIMG=file.name.split('.')[file.name.split('.').length - 1] === 'jpg' || file.name.split('.')[file.name.split('.').length - 1] === 'jpeg' || file.name.split('.')[file.name.split('.').length - 1] === 'png'
			const isLt10M = file.size / 1024 / 1024 < 10;
			if (!isIMG) {
				this.$message.error(this.$t('lang.Uploadavatarimagecanonlybe')+'JPG、PNG、JPEG'+this.$t('lang.format'))
				this.fileList=[]
				this.filessa=false
				return this.filessa
			}
		},
		//提交
    subWorkMsg() {
			console.log(this.userdiser)
      let userid = JSON.parse(sessionStorage.getItem("user")).id;
      let type = parseInt(sessionStorage.getItem("type"));
			
			if(this.userdiser.problemtext==''){
				this.$message.error(this.$t('lang.Pleasecompletetheworkorderinformation'))
				return false
			}
			/* if(this.userdiser.phone && !RegExp.mobile.test(this.userdiser.phone)){
				this.$message.error(this.$t('lang.Pleaseenterthecorrectcontactphone'))
				return false
			} */
			if(this.userdiser.email && !RegExp.email.test(this.userdiser.email)){
				this.$message.error(this.$t('lang.Pleaseenterthecorrectemail'))
				return false
			}
			let workmsg = {
			  description: {
			    description: this.userdiser.problemtext,
			  },
			  type: type,
			  userID: userid,
				email:this.userdiser.email,
				phone:this.userdiser.phone
			};
			if(this.userdiser.file.length>0){
				workmsg.description.file=this.userdiser.file
			}
			//console.log(workmsg)
      work.workUpload(workmsg).then((res) => {
        this.$message.success(this.$t("lang.Submitted"));
				this.$router.push({
					path: 'workorder'
				})
      });
    },
		//返回
		retuen(){
			this.$router.push({
				path: 'workorder'
			})
		}
  },
  computed: {
    typemsg(val) {
      switch (val) {
        case val == "1":
          val = this.$t("lang.userRegistrationClass");
          break;
        case val == "2":
          val = this.$t("lang.liveBroadcastTerminalClass");
          break;
        case val == "3":
          val = this.$t("lang.streamingMediaPushClass");
          break;
      }
      return val;
    },
		headers() {
			return{
				"Authorization": this.tokens // 直接从本地获取token就行
			}
		}
  },
};
</script>

<style lang="less" scoped>
.bgcard {
  border: 0px;
  border-radius: 2px;
  background: #222628;
  padding: .39rem 0px .2rem .38rem;
}
.line {
  width: 4px;
  height: .3rem;
  background: #b2b2b2;
  border-radius: 2px;
  margin-right: .11rem;
}
.title {
  display: flex;
  margin-bottom: .18rem;
  p {
    font-size: .18rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #b2b2b2;
    line-height: .3rem;
  }
}
.questionlist {
  margin-left: .12rem;
  p {
    font-size: .16rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #b2b2b2;
    margin-right: .16rem;
  }
  .questionone {
    display: flex;

    p {
      margin-top: .22rem;
      display: inline-block;
      min-width: 1.8rem;
      text-align: right;
    }
    textarea {
      width: 6.56rem;
      height: 1rem;
      outline-style: none;
      border: none;
      background: #161a1c;
      border-radius: 4px;
      font-size: .16rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: #4a5053;
      line-height: .35rem;
      text-indent: .16rem;
      padding-top: .12rem;
    }
  }
  .questiontwo {
    display: flex;
    align-items: center;
    margin-top: .13rem;
    p{
      display: inline-block;
      min-width: 1.8rem;
      text-align: right;
    }
    input {
      width: 2.38rem;
      height: .54rem;
      border: none;
      outline-style: none;
      background: #161a1c;
      border-radius: 4px;
      text-indent: .23rem;
      font-size: .16rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: #4a5053;
    }
    span {
      margin-left: .23rem;
      font-size: .16rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: #4a5053;
      line-height: .35rem;
    }
  }
  .questionthree {
    display: flex;
    align-items: center;
    margin-top: .13rem;
    p{
      display: inline-block;
      min-width: 1.8rem;
      text-align: right;
    }
    input {
      width: 2.38rem;
      height: .54rem;
      border: none;
      outline-style: none;
      background: #161a1c;
      border-radius: 4px;
      text-indent: .23rem;
      font-size: .16rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: #4a5053;
    }
		span {
		  margin-left: .23rem;
		  font-size: .16rem;
		  font-family: PingFang SC;
		  font-weight: 500;
		  color: #4a5053;
		  line-height: .35rem;
		}
  }
  .questionfour {
    display: flex;
    margin-top: .15rem;
    margin-left: .12rem;
    padding-bottom: .8rem;
		p{
			height: .54rem;
			line-height: .54rem;
			margin-top: 0px;
      display: inline-block;
      min-width: 1.7rem;
      text-align: right;
		}
  }
}
.btnstyle {
  margin-left: 1.14rem;
  margin-bottom: .7rem;
  button {
    outline-style: none;
    border: none;
    &:first-of-type {
      width: 1.31rem;
      height: .54rem;
      background: #307b37;
      border-radius: 4px;
      font-size: .16rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: #ffffff;
      margin-right: .16rem;
    }
    &:last-of-type {
      width: 1.31rem;
      height: .54rem;
      background: #222628;
      border: 1px solid #307b37;
      border-radius: 4px;
      font-size: .18rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: #307b37;
    }
  }
}
.questionfour .el-button{
  width: auto;
}
</style>
